@import 'assets/styles/colors';

.container {
  margin-top: 2rem;
  width: 100%;
}

.logo {
  max-width: 10rem;
}

.logoContainer {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
}

.title {
  margin: 2rem 0;
  text-align: center;
}

.link {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.spacing {
  margin-bottom: 3rem;
}

.bold {
  font-weight: bold;
}
