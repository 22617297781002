@import 'assets/styles/colors';

.container {
  display: flex;
  width: 100%;
  align-self: center;
  justify-content: center;
}

.divider {
  width: 80%;
  display: flex;
  border-top: 1px solid $primary-light-violet;
}
