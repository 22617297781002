@import 'assets/styles/colors';
@import 'assets/styles/breakpoints';

.container {
  display: flex;
  justify-content: center;
  max-height: 100%;
  height: 100%;
  overflow: auto;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
}

.logo {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.title {
  text-align: center;
}

.subtitle {
  text-align: center;
  width: 45%;
  align-self: center;
  margin-top: 0;
}

.podcasts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem 0;
  width: 80%;
  height: fit-content;
  align-self: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nextButton {
  width: 40%;
  margin-bottom: 0.5rem;
}

.seeMoreButton {
  border: none;
  margin-bottom: 2rem;
  color: $primary-dark-violet;
  font-weight: bold;
  font-size: 0.65rem;
  text-align: center;
}

.spinner {
  margin-top: 15rem;
  align-self: center;
}

.noPodcasts {
  display: flex;
  align-self: center;
  margin: 5rem 0;
}

.podcasts::-webkit-scrollbar {
  display: none;
}

.podcastModal {
  width: 45%;
  height: 70%;
}

@media screen and (max-width: $max-medium-size) {
  .content {
    width: 90%;
  }

  .subtitle {
    width: 90%;
  }

  .podcasts {
    width: 100%;
  }
}
