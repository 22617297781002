@import 'assets/styles/colors';
@import 'assets/styles/breakpoints';

.container {
  display: flex;
  justify-content: center;
  max-height: 100%;
  width: 100%;
  flex-direction: column;
}

.title {
  text-align: center;
}

.components {
  display: flex;
  width: 100%;
  margin-top: 1rem;
  justify-content: center;
}

.summary {
  display: flex;
  width: 40%;
  margin-left: 2rem;
}

.selectedPodcasts {
  width: 45%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: $secondary-light-violet;
  border: 1px solid $primary-gray;
  margin-right: 3rem;
  padding-bottom: 2rem;
}

.selectedPodcastsTitle {
  margin-left: 1rem;
}

.addPodcast {
  margin: 1rem;
  margin-top: 0;
}

.deletePodcastModal {
  height: fit-content;
  z-index: 10000;
}

.spinner {
  margin-top: 15rem;
  align-self: center;
}

.checkoutButton {
  width: 50%;
  align-self: center;
  margin: 2rem 0 0 0;
}

.adTypeError {
  display: flex;
  text-align: center;
  color: $primary-red;
  justify-content: center;
}

@media screen and (min-width: $min-tablet-size) and (max-width: $min-full-desktop-size) {
  .summary {
    width: 30%;
  }

  .selectedPodcasts {
    width: 67%;
  }
}

@media screen and (max-width: $min-tablet-size) {
  .components {
    flex-direction: column-reverse;
  }

  .selectedPodcasts {
    width: 80%;
    align-self: center;
    margin-right: 0;
  }

  .summary {
    margin-right: 0;
    margin-top: 2rem;
    margin-left: 0;
    width: 80%;
    align-self: center;
  }
}
