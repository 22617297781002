@import 'assets/styles/breakpoints';

.container {
  display: flex;
  justify-content: center;
  max-height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  text-align: center;
}

.tabs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerButton {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  .button {
    width: 30%;
    font-size: 12px;
  }
}

.spinner {
  margin-top: 15rem;
  align-self: center;
}

.campaigns {
  display: flex;
  flex-direction: column;
  width: 55%;
}

.campaignSummaryModal {
  width: 40%;
  height: 80%;
  overflow-y: auto;
}

.exportCampaignModal {
  max-width: fit-content;
}

.adCopyModal {
  width: 40%;
  height: auto;
  padding-top: 1rem;
  overflow-y: auto;
}

.notesToTheBrandModal {
  width: 40%;
  height: 50%;
  padding-top: 1rem;
  overflow-y: auto;
}

.newCampaign {
  display: flex;
  position: fixed;
  right: 2rem;
  top: 1.5rem;
  z-index: 500;
  align-items: center;
}

.newCampaignText {
  margin-left: 0.5rem;
}

.noCampaigns {
  text-align: center;
  margin: 5em;
}

@media screen and (max-width: $min-full-desktop-size) and (min-width: $min-wide-desktop-size) {
  .campaigns {
    width: 65%;
  }
}

@media screen and (max-width: $min-wide-desktop-size) and (min-width: $min-desktop-size) {
  .campaigns {
    width: 75%;
  }
}

@media screen and (max-width: $min-desktop-size) and (min-width: $min-tablet-size) {
  .campaigns {
    width: 85%;
  }
}

@media screen and (max-width: $min-tablet-size) {
  .newCampaign {
    flex-direction: column;
    right: 1rem;
  }

  .newCampaignText {
    width: min-content;
    margin-left: 0;
  }

  .campaigns {
    width: 90%;
  }
}

@media screen and (max-width: $max-small-size) {
  .exportCampaignModal {
    max-width: max-content;
    width: 98% !important;
  }
}
