@import 'assets/styles/colors';

.container {
  display: flex;
  flex-direction: column;
  height: 86%;
  align-items: center;
  width: 90%;
  justify-content: space-between;
}

.logo {
  margin-top: 2rem;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.subtext {
  text-align: center;
  margin: 0;
  text-align: center;
}

.icon {
  width: 18%;
}
