@import 'assets/styles/breakpoints';
@import 'assets/styles/colors';

.containerGrids {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.container {
  display: flex;
  justify-content: center;
}

.containerTexts {
  border-left: 1px solid $primary-dark-violet;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
  padding-left: 0.5rem;
  margin: 10px;

  .description {
    margin: 0;
    text-align: left;
  }
}

.container {
  display: block;
  margin: auto;
  width: 50%;
}

.grid {
  flex: 0 0 calc(50% - 20px);
  margin: 10px;
  border-left: 1px solid $primary-dark-violet;
  height: fit-content;
  text-align: start;
  padding-left: 0.5rem;

  .body {
    font-weight: 700;
    font-size: 0.875rem;
  }
}

.logoContainer {
  margin: 1rem;
  padding: 5%;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    width: 8em;
    height: 8em;
  }
}

.title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.numbersEstimated {
  font-weight: 600;
  text-transform: uppercase;
}

.estimatedImpressions {
  display: flex;
  justify-content: space-around;
  margin: 45px 0px;
  align-items: center;
  .number {
    font-weight: 600;
    font-size: 4em;
  }
}

.checkbox {
  margin: 10px;
  .labelCheckBox {
    font-size: 14px;
    text-align: start;
  }
}

.buttonsContainer {
  margin-top: 3em;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
}
