@import 'assets/styles/colors';
@import 'assets/styles/breakpoints';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $primary-dark-violet;
  border-radius: 16px;
  margin: 0.5rem;
  padding: 1rem;
  width: 25%;
  max-width: 25%;
  position: relative;
}

.iconButton {
  width: 100%;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
}

.image {
  width: 100%;
}

.title {
  font-weight: bold;
}

.subtitle {
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  -webkit-box-orient: vertical;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 80;
  background: linear-gradient(
    to top,
    $secondary-dark-violet,
    $primary-dark-violet
  );
  opacity: 0.7;
  border-radius: 16px;
}

.selectedIcon {
  z-index: 90;
  position: absolute;
  top: 5rem;
}

.learnMoreButton {
  margin-top: 1rem;
}

.logo {
  max-width: 100%;
}

@media screen and (max-width: $min-medium-size) {
  .container {
    width: 30%;
    max-width: 30%;
  }
}

@media screen and (min-width: $min-medium-size) and (max-width: $max-medium-size) {
  .container {
    width: 20%;
    max-width: 20%;
  }
}
