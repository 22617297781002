@import 'assets/styles/colors';

.item {
  border-radius: 16px;
  height: 3rem;
  width: 80%;
  align-self: center;
  padding-left: 1rem;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 0.5rem;
  width: 1.2rem;
}

.selected {
  width: 0.2rem;
  height: 1.5rem;
  background: linear-gradient(
    to bottom,
    $primary-light-violet,
    $primary-dark-violet
  );
  margin-right: 1rem;
}

.body {
  font-weight: bold;
}
