@import 'assets/styles/colors';

.container {
  display: flex;
  flex-direction: column;
  height: 86%;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}

.logo {
  margin-top: 2rem;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtext {
  width: 60%;
  text-align: center;
  margin: 0;
}

.icon {
  width: 12%;
}

.button {
  margin-top: 2rem;
}

.header {
  display: flex;
}

.logout {
  position: fixed;
  top: 1rem;
  right: 1rem;
}

.logoutIcon {
  width: 2rem;
  height: 2rem;
  transform: rotate(-90deg);
}

.tooltip {
  position: fixed;
  top: 3.5rem;
  right: 2.5rem;
  border-radius: 8px;
  border-top-right-radius: 0;
}

.tooltipText {
  margin: 0;
}
