@import 'assets/styles/colors';
@import 'assets/styles/breakpoints';

.content {
  margin: 0 auto;
}

.buttons {
  width: 100%;
}

.customTabs {
  ul {
    justify-content: space-around !important;
  }
}

.form {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
}

@media screen and (min-width: $max-small-size) {
  .form {
    flex-wrap: nowrap;
    width: 60%;
  }

  .content {
    width: 60%;
  }
}

@media screen and (min-width: $min-desktop-size) {
  .buttons {
    width: 30%;
  }
}