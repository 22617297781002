@import 'assets/styles/colors';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.dropContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 10rem;
  width: 12rem;
  border: 1px dashed $primary-gray;

  &:hover {
    cursor: pointer;
  }
}

.text {
  text-align: center;
  margin: 0;
}

.browseText {
  margin: 0;
  color: $primary-dark-violet;
  text-align: center;
}

.fileInput {
  display: none;
}

.uploadedFile {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.errorMessage {
  color: $primary-red;
}

.logoDisplay {
  display: flex;
  max-width: 10rem;
  max-height: 4rem;
  margin: 0.5rem;
}
