.image {
  width: 12rem;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  gap: 1rem;
}

.modalInformation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalInformationTitle {
  margin-bottom: 0;
}

.modalInformationSubtitle {
  width: 80%;
  text-align: center;
}

.daysContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.releaseDays {
  font-weight: 600;
}

.podcastName {
  text-align: center;
}

.content {
  max-width: 95% !important;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10rem 5rem;
}
