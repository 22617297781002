@import 'assets/styles/breakpoints';
@import 'assets/styles/colors';

.fields {
  margin: 0 auto;
  width: 100%;
}

.submitButton {
  margin-top: 8rem;
  width: 70%;
}

@media screen and (min-width: $max-small-size) {
  .submitButton {
    width: 55%;
  }
}

@media screen and (min-width: $min-medium-size) {
  .submitButton {
    width: 45%;
  }
}

@media screen and (min-width: $min-tablet-size) {
  .submitButton {
    width: 40%;
  }
}

@media screen and (min-width: $min-desktop-size) {
  .submitButton {
    width: 35%;
  }
}

@media screen and (min-width: $min-wide-desktop-size) {
  .submitButton {
    width: 30%;
  }
}
