@import 'assets/styles/colors';

.container {
  display: flex;
  flex-direction: column;
  height: 86%;
  justify-content: center;
  width: 18rem;
}

.logo {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.title {
  margin: 2rem 0;
  text-align: center;
}

.link {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}

@media screen and (max-width: 760px) {
  .container {
    width: 80%;
  }
}
