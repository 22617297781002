@import 'assets/styles/colors';
@import 'assets/styles/breakpoints';

.container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding: 2rem 0;
}

.hasBorder {
  border-top: 1px dashed $primary-gray;
}

.left {
  display: flex;
  width: 75%;
}

.right {
  display: flex;
  flex-direction: column;
  max-width: 25%;
}

.image {
  width: 5rem;
  height: 5rem;
  height: auto;
  align-self: center;
}

.info {
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
}

.bold {
  margin: 0;
  font-weight: bold;
}

.budget {
  border-left: 1px solid $primary-dark-violet;
  margin-left: 1rem;
  padding: 0.5rem;
  color: $primary-red;
}

.budgetContainer {
  display: flex;
  flex-wrap: wrap;
}

.episode {
  border: 1px solid $primary-dark-violet;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.impressions {
  border-left: 1px solid $primary-dark-violet;
  margin-left: 1rem;
  padding: 0.5rem;
  padding-right: 0;
  display: flex;
  align-self: center;
}

.body {
  margin: 0 0.2rem;
}

.adTypes {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}

.selectedAdType {
  display: flex;
  margin-right: 1rem;
  align-items: center;
  margin-bottom: 0.5rem;
}

.selected {
  width: 1.2rem;
  height: 1rem;
  background: linear-gradient(
    to right,
    $primary-light-violet,
    $primary-dark-violet
  );
  border-radius: 5px;
  margin-right: 0.5rem;
}

.offerStatus {
  display: flex;
  align-items: center;
}

.actionButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  align-items: center;
  background: $secondary-light-violet;

  .editTalkingPointsButton {
    padding: 0.5em 0.5em;
  }
}

.noWrap {
  white-space: nowrap;
}

.status {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 10px;
  margin-right: 0.5rem;
}

.green {
  background-color: $primary-light-green;
}

.yellow {
  background-color: $primary-yellow;
}

.red {
  background-color: $primary-red;
}

.rejectedContainer {
  background-color: $secondary-red;
  margin-bottom: 1.5rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}

.newPodcastButton {
  border: none;
  margin-left: 1rem;
  min-width: max-content;

  &:hover {
    background-color: $secondary-red;
    color: $primary-white;
  }
}

.notesToTheBrandButton {
  margin: 1rem 0;
}

.notesToTheBrandModal {
  width: 40%;
  height: 50%;
  padding-top: 1rem;
  overflow-y: auto;
}

@media screen and (max-width: $min-medium-size) {
  .noWrap {
    white-space: unset;
  }

  .adTypes {
    flex-direction: column;
  }

  .selectedAdType {
    margin-right: 0;
    align-items: left;
    margin-bottom: 0.5rem;
  }

  .info {
    padding: 0;
    margin-top: 1rem;
  }

  .left {
    flex-direction: column;
    width: 100%;
  }

  .right {
    position: absolute;
    right: 0;
    margin-right: 10%;
    width: max-content;
    max-width: 100%;
  }

  .image {
    align-self: flex-start;
  }

  .container {
    width: 80%;
  }
}
