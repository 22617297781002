@import 'assets/styles/breakpoints';
@import 'assets/styles/colors';

.fields {
  margin: 2rem auto 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: $max-small-size) {
  .submitButton {
    width: 55%;
  }
}

@media screen and (min-width: $min-medium-size) {
  .submitButton {
    width: 45%;
  }
}

@media screen and (min-width: $min-tablet-size) {
  .submitButton {
    width: 40%;
  }
}

@media screen and (min-width: $min-desktop-size) {
  .submitButton {
    width: 35%;
  }
}

@media screen and (min-width: $min-wide-desktop-size) {
  .submitButton {
    width: 30%;
  }
}

.spinner {
  margin-top: 15rem;
  align-self: center;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
}
