@import 'assets/styles/colors';

.container {
  display: flex;
  flex-direction: column;
  height: 86%;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.icon {
  width: 20%;
}

.subtext {
  text-align: center;
  margin: 0;
  margin-bottom: 1rem;
}

.spinner {
  margin-top: 15rem;
  align-self: center;
}

.button {
  margin-top: 1rem;
}
