.categories {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  margin: 0 auto 1.2rem;
}

.badge {
  white-space: nowrap;
}
