@import 'assets/styles/breakpoints';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}

.img {
  height: 6.375rem;
}

.notesToTheBrand {
  text-align: center;
  overflow: scroll;
  max-height: 20rem;
  word-break: break-all;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  min-height: 2.5rem;
  width: 100%;
}

@media screen and (min-width: $max-small-size) {
  .button {
    width: 65%;
  }
}

@media screen and (min-width: $min-medium-size) {
  .button {
    width: 55%;
  }
}

@media screen and (min-width: $min-desktop-size) {
  .container {
    min-width: 15rem;
  }

  .button {
    width: 45%;
  }
}

@media screen and (min-width: $min-wide-desktop-size) {
  .button {
    width: 35%;
  }
}
