@import 'assets/styles/colors';

.container {
  display: flex;
  flex-direction: column;
  height: 86%;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logo {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  max-width: 10rem;
}

.logoImage {
  width: 100%;
}

.title {
  margin: 2rem 0;
  text-align: center;
}

.link {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}

.or {
  display: flex;
  justify-content: center;
  width: 30%;
}

.button {
  width: 18rem;
}

.bold {
  font-weight: bold;
}

@media screen and (max-width: 760px) {
  .button {
    width: 70%;
  }

  .or {
    width: 60%;
  }
}
