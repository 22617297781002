@import 'assets/styles/colors';

.button {
  font-family: 'Monserrat';
  border: 0.1rem solid $primary-dark-violet;
  cursor: pointer;
  transition: background-color 125ms linear;
  padding: 0 1rem;
}

.upperCase {
  text-transform: uppercase;
}

.isFullWidth {
  width: 100%;
}

.primary {
  border-radius: 25px;
  background: linear-gradient(
    to right,
    $primary-light-violet,
    $primary-dark-violet
  );
  color: $primary-white;

  &:hover {
    box-shadow: 3.2px 3.2px 3.2px 3.2px $secondary-dark-violet;
  }

  &:active {
    opacity: 0.8;
  }
}

.secondary {
  width: fit-content;
  border-radius: 5px;
  background: $primary-white;
  border: 1px solid $primary-dark-violet;
  color: $primary-black;

  &:hover {
    background: $secondary-dark-violet;
  }

  &:active {
    opacity: 0.8;
  }

  &.loading:hover {
    background: $primary-white;
  }
}

.tertiary {
  border-radius: 25px;
  background: $primary-gray;
  color: $primary-white;

  &:hover {
    box-shadow: 0.2rem 0.2rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  }

  &:active {
    opacity: 0.8;
  }

  &.loading:hover {
    background: $primary-gray;
  }
}

.large {
  font-size: 1.125rem;
  font-weight: 500;
  height: 2.5rem;
  letter-spacing: 0.139rem;
}

.small {
  font-size: 0.563rem;
  font-weight: 500;
  height: 1.5rem;
  letter-spacing: 0.081rem;
}

.disabled {
  &:hover {
    cursor: not-allowed;
  }
}

.spinner {
  display: flex;
  justify-content: center;
}
