.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5rem;
}

.img {
  height: 6.375rem;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  padding-top: 1.5rem;
  text-align: center;
  overflow: scroll;
  max-height: 20rem;
}

.addressContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9ff;
  padding: 1rem;
  width: auto;
  min-width: 60%;
  text-align: center;
  margin: 1rem 0;
}

.names {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name {
  margin: 0.5rem 0 0 0;
}

.address {
  margin: 1rem 0;
}

.addresLine {
  margin: 0.1rem 0;
}
