@import 'assets/styles/colors';
@import 'assets/styles/breakpoints';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

.information {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 40%;
  gap: 1.5rem;
  justify-content: right;
}

.item {
  display: flex;
  flex-direction: column;
  width: 95%;
  border-left: 1px solid $primary-dark-violet;
  padding-left: 0.5rem;
  height: fit-content;
}

.itemTitle {
  margin-top: 0;
}

.informationText {
  margin-top: 0;
  margin-bottom: 0;
}

.descriptionGrid {
  grid-column: span 2;
}

.category {
  display: flex;
  margin-top: 0;
}

.rating {
  width: 1.5rem;
}

.title {
  align-self: center;
  margin-bottom: 2rem;
  margin-top: 0;
}

.removeMargin {
  justify-content: center;
}

.removeMarginDescription {
  width: 85%;
  align-self: center;
  margin-left: 1rem;
}

.categories {
  display: flex;
  flex-flow: wrap;
  grid-auto-rows: min-content;
}

.green {
  color: $primary-green;
}

.countries {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: $min-tablet-size) and (max-width: $min-full-desktop-size) {
  .information {
    display: flex;
    gap: 0.7rem;
    justify-content: right;
    flex-direction: column;
  }
}

@media screen and (max-width: $min-tablet-size) {
  .information {
    grid-template-rows: repeat(9, 1fr);
    grid-auto-columns: 100%;
    gap: 1rem;
  }

  .descriptionItem {
    width: 100%;
    margin-top: 1rem;
    padding-bottom: 1rem;
    margin-left: 0;
  }

  .removeMarginDescription {
    margin-left: 0.5rem;
  }

  .informationTextDescription {
    padding-bottom: 1rem;
  }
}
