.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 18rem;
}

@media screen and (max-width: 760px) {
  .form {
    width: 70%;
  }
}
