@import 'assets/styles/colors';

.spacing {
  margin-bottom: 3rem;
}

.marginTop {
  margin-top: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.formControl {
  width: 18rem;
}

.formControlSmall {
  width: 18rem;
}

.button {
  width: 18rem;
}

.buttonSmall {
  width: 18rem;
}

.logo {
  display: flex;
  flex-direction: column;
  background-color: $secondary-light-violet;
  width: 100%;
  align-items: center;
  margin: 1rem 0 3rem 0;
  padding-bottom: 1rem;
}

.logoDescription {
  text-align: center;
}

.uploadFile {
  width: 100%;
  display: flex;
  justify-content: center;
}

.logoTitle {
  text-align: center;
  width: 90%;
}

@media screen and (max-width: 760px) {
  .formControl {
    width: 70%;
  }

  .formControlSmall {
    width: 70%;
  }

  .button {
    width: 70%;
  }

  .buttonSmall {
    width: 70%;
  }
}
