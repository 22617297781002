.header {
  margin: 1rem;
  margin-left: 0;
  margin-right: 4rem;
  max-width: 10rem;
}

.logoImage {
  width: 100%;
}
