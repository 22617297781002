@import 'assets/styles/colors';
@import 'assets/styles/breakpoints';

.content {
  width: 90% !important;
  margin: 0 auto;
}

.buttons {
  width: 100%;
}

.categoriesButton {
  background: none;
  border: none;
  padding: 0.25rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.063rem;
  color: $primary-dark-violet;
}

.form {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
}

.estimatedImpressions {
  margin: 2rem auto 0;
  width: 100%;
  flex-direction: row !important;
  justify-content: center;
}

.estimatedImpressionsTitle {
  font-size: 2rem !important;
}

.fields {
  margin: 2rem auto 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.categoriesContainer {
  width: 90%;
  justify-content: center;
}

@media screen and (min-width: $max-small-size) {
  .form {
    flex-wrap: nowrap;
  }

  .content {
    width: 60%;
  }

  .categoriesContainer {
    justify-content: flex-start;
    width: 70% !important;
  }

  .estimatedImpressionsTitle {
    font-size: 4rem !important;
  }
}

@media screen and (min-width: $min-desktop-size) {
  .categoriesContainer {
    width: 60% !important;
  }

  .estimatedImpressions {
    flex-direction: column !important;
    margin: 3rem 0 0;
    width: 30%;
    position: fixed;
    left: 0;
  }

  .buttons {
    width: 30%;
  }
}
