.text {
  text-transform: uppercase;
}

.data {
  display: flex;
  flex-direction: column;
  font-weight: 600;

  .title {
    font-size: 4rem;
  }
}

.separator {
  margin: 1.5rem 0;
  font-weight: 500;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
