@import 'assets/styles/breakpoints';

.container {
  display: flex;
  justify-content: center;
  max-height: 100%;
  overflow: auto;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  text-align: center;
}

.tabs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  margin-top: 15rem;
  align-self: center;
}

.newCampaign {
  display: flex;
  position: fixed;
  right: 2rem;
  top: 1.5rem;
  z-index: 500;
  align-items: center;
}

.newCampaignText {
  margin-left: 0.5rem;
}

@media screen and (max-width: $min-tablet-size) {
  .newCampaign {
    flex-direction: column;
    right: 1rem;
  }

  .newCampaignText {
    width: min-content;
    margin-left: 0;
  }
}
