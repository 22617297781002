@import 'assets/styles/breakpoints';

.footer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: last baseline;
  padding: 1.25rem;
  gap: 1.25rem;
}

.logo {
  width: 3.125rem;
  height: 1.25rem;
  margin-left: 0.5rem;
  margin-right: 1rem;
}

.footerLink {
  text-decoration: none;
}

.footerLink:hover {
  text-decoration: underline;
}

@media screen and (max-width: $min-medium-size) {
  .footer {
    flex-wrap: wrap;
  }
}
