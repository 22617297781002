@import 'assets/styles/breakpoints';

.container {
  width: 100%;
  min-width: 32rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.content {
  width: 100%;
  display: flex;
}

.buttonContainer {
  position: absolute;
  bottom: 2.5em;
}

.button {
  font-size: 12px;
  padding: 10px;
}

.mobileButtonContainer {
  display: none;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  width: 12rem;
  margin-right: 1rem;
}

.rightColumn {
  width: 100%;
}

.title {
  font-weight: bold;
  margin: 0.5rem;
}

.description {
  max-height: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;

  &:hover {
    text-overflow: unset;
    overflow: scroll;
    -webkit-line-clamp: unset;
  }
}

.description::-webkit-scrollbar {
  display: none;
}

.badge {
  display: flex;
  flex-flow: wrap;
}

.information {
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
}


.item {
  display: flex;
  margin: 0.25rem 0;
  width: 45%;
  padding: 0.25em 0;
  .informationText {
    font-weight: bold;
    font-size: 14px;
    margin: 5px 0;
  }
  .itemTitle {
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
}

.icon {
  display: flex;
  margin-right: 1rem;
}


.image {
  width: 12rem;
}

.media {
  height: 1.2rem;
  display: flex;
  margin-top: 0.5rem;
}

.mediaIcon {
  display: flex;
  margin-right: 0.5rem;
  width: 1.2rem;
}

.rating {
  width: 1.5rem;
}

.categories {
  height: 25px;
  cursor: default;
}

.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

// @media screen and (max-width: $min-tablet-size) {
//   .container {
//     overflow-y: scroll;
//     overflow-x: hidden;
//     min-width: auto;
//   }

//   .media {
//     margin-left: 0.5rem;
//   }

//   .content {
//     flex-direction: column;
//     height: max-content;
//   }

//   .buttonContainer {
//     display: none;
//   }

//   .mobileButtonContainer {
//     display: flex;
//     height: 15%;
//   }

//   .mobileButton {
//     margin-top: 0.2rem;
//     width: 100%;
//   }

//   .image {
//     width: 5rem;
//   }

//   .leftColumn {
//     flex-direction: row;
//     width: 100%;
//     height: 6rem;
//     margin-bottom: 2rem;
//   }

//   .description {
//     width: 100%;
//     margin-left: 0.5rem;
//     margin-top: 0;
//     -webkit-line-clamp: 5;
//     line-clamp: 5;
//     max-height: 6rem;
//   }

//   .information {
//     height: 100%;
//   }

//   .container::-webkit-scrollbar {
//     display: none;
//   }
// }
