@import 'assets/styles/colors';

.container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 1rem;
  align-self: center;
}

.content {
  display: flex;
  border-bottom: 1px solid $primary-dark-violet;
  padding-bottom: 1rem;
}

.budget {
  display: flex;
  flex-direction: column;
  border-left: 1px solid $primary-dark-violet;
  padding-left: 1rem;
  margin-right: 1rem;
}

.budgetTitle {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.number {
  margin: 0;
}

.green {
  color: $primary-green;
}

.negative {
  color: $primary-red;
}

.learnMore {
  font-weight: bold;
}

.text {
  display: flex;
}

.info {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
}

.tooltip {
  width: fit-content;
}

.tooltipText {
  margin: 0;
  white-space: normal;
  width: 10rem;
}

.tooltipMargin {
  margin-top: -2.6rem;
}

.lastTooltipMargin {
  margin-top: -2.6rem;
  margin-left: -10rem;
  border-radius: 8px;
  border-bottom-right-radius: 0;
}
