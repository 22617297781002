@import 'assets/styles/colors';
@import 'assets/styles/breakpoints';

.releaseDatesContainer {
  .react-datepicker__day--highlighted {
    border-radius: 50% !important;
    color: $primary-white !important;
    background: linear-gradient(
      to right,
      $primary-light-violet,
      $primary-dark-violet
    );
  }

  .react-datepicker {
    margin: 0 1.88rem;
    padding: 0;
    border: none !important;
    padding: 0.313rem 1rem !important;
    border-radius: 0.625rem !important;
    box-shadow: 0 0.25rem 0.5rem $border-background-panel !important;
  }

  .react-datepicker-year-header {
    font-weight: 500;
    color: $primary-black;
  }

  .react-datepicker__day-names {
    display: flex;
    gap: 0.4rem;
    margin-top: 1rem;
    border-bottom: 1px solid $primary-gray;
  }

  .react-datepicker__day-name {
    font-weight: 400;
    width: 2.125rem;
    text-transform: uppercase;
  }

  .react-datepicker__month {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    gap: 0.2rem;
  }

  .react-datepicker__day--today {
    font-weight: 400;
  }

  .react-datepicker__day--outside-month {
    color: transparent !important;
    background: transparent !important;
    pointer-events: none;
  }

  .react-datepicker__week {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
  }

  .react-datepicker__day {
    width: 2.125rem;
    height: 2.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default !important;

    &:hover {
      cursor: default !important;
      background-color: transparent !important;
      color: $primary-black;
    }
  }

  .react-datepicker__day--disabled:hover {
    cursor: default !important;
    background-color: transparent !important;
    color: #ccc;
  }
}
