@import 'assets/styles/colors';
@import 'assets/styles/breakpoints';

.container {
  display: flex;
  width: 50%;
  padding: 2rem;
}

.hasBorder {
  border-top: 1px dashed $primary-gray;
}

.image {
  width: 5rem;
  height: 5rem;
  align-self: center;
}

.info {
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
  align-self: center;
}

.campaign {
  border-left: 1px solid $primary-dark-violet;
  padding-left: 0.5rem;
}

.title {
  margin-top: 0;
  font-weight: bold;
}

.text {
  margin: 0;
}

.campaignName {
  margin: 0;
  margin-top: 0.5rem;
  font-weight: bold;
}

@media screen and (max-width: $max-medium-size) {
  .container {
    width: 85%;
    padding: 1rem 0;
  }
}
