.container {
  margin-bottom: 1.8rem;
  width: 100%;
}

.error {
  margin-bottom: 0;
}

.top {
  display: flex;
  flex-direction: column;
}

.label {
  margin-left: 0.25rem;
  margin-right: 0.5rem;
}

.datePicker {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.errorText {
  margin-left: 0.2rem;
}

.disabledCursor {
  cursor: not-allowed !important;
}

.input {
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

.inputWrapper {
  position: relative;
}

.icon {
  position: absolute;
  top: 1rem;
  right: 0.75rem;
  cursor: pointer;
}
