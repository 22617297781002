.buttonList {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
}

.subtext {
  margin-top: 0;
}
