// Primary Colors
$primary-dark-violet: #645aff;
$primary-light-violet: #a573ff;
$primary-gray: #b9b9b9;
$primary-black: black;
$primary-white: white;
$primary-red: #ff0000;
$primary-green: #179c00;
$primary-light-green: #6dd400;
$primary-yellow: #efec25;
$secondary-red: #e02020;

$background-image: #050e13;
$background-panel: #f9f9ff;
$border-background-panel: #dadada;

// Secondary Colors
$secondary-gray: #e8e8e8;
$secondary-dark-violet: #e2e0ff;
$secondary-light-violet: #f8f4ff;
$secondary-red: #e020204d;

// Gradients
$tertiary-gradient-light-gray: #bbbbbb;
$tertiary-gradient-dark-gray: #727272;
