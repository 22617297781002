@import 'assets/styles/colors';
@import 'assets/styles/breakpoints';

.container {
  border: 1px solid $primary-dark-violet;
  border-radius: 10px;
  margin-top: 1rem;
  width: 100%;
  transition: all 0s ease-in-out 0.6s;
  position: relative;
}

.headerContent {
  position: sticky;
  padding: 1rem;
  .statistics {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    justify-content: space-between;
  }

  .adFormatContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    height: 30px;
    border-radius: 0 8px 0 0;
    top: 0;
    width: 28%;
    background: linear-gradient(
      to right,
      $primary-light-violet,
      $primary-dark-violet
    );
    .title {
      font-size: 14px;
      color: white;
      font-weight: normal;
    }
  }
  .contentRow {
    margin: 1em 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .containerLeftColumn {
      display: flex;
      .containerText {
        margin: 0 1em;
      }
    }
    .title {
      font-weight: bold;
      margin-bottom: 0;
    }
  }
  .campaignActions {
    display: flex;
    flex-direction: column;
  }
}

.cancelCampaign {
  display: flex;
  align-items: center;
}

.noContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.campaignDetails {
  display: flex;
  justify-content: space-between;
  padding: 0 0 1rem;
}

.details {
  display: flex;
  flex-direction: column;
  border-left: 1px solid $primary-dark-violet;
  padding-left: 0.5rem;
  margin: 1rem 3rem;
  margin-left: 0;
}

.text {
  margin: 0;
}

.budgetAmount {
  margin-top: 0.5rem;
  font-weight: bold;
}

.moreInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.seeMore {
  display: flex;
  height: fit-content;
  align-items: center;
  padding: 0;
  margin-top: 0.5rem;
}

.offerDetailsContainer {
  background-color: $secondary-light-violet;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  max-height: 400px;
  overflow: auto;
}

.offerDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottomBorder {
  border-bottom: 1.5px dashed $border-background-panel;
}

.estimateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  width: 30%;
}

.payButton {
  width: 23%;
  font-size: 14px;
  margin-left: 1rem;
}

.value {
  margin: 0;
}

.label {
  margin: 0;
  text-align: center;
}

.offerContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 0;
}

.budgetContainer {
  display: flex;
  flex-wrap: wrap;
}

.episode {
  border: 1px solid $primary-dark-violet;
  padding: 0.5rem;
  border-radius: 8px;
}

.impressions {
  border-left: 1px solid $primary-dark-violet;
  margin-left: 1rem;
  padding: 0.5rem;
  padding-right: 0;
  display: flex;
  align-self: center;
}

.body {
  margin: 0 0.2rem;
}

.budget {
  border-left: 1px solid $primary-dark-violet;
  margin-left: 1rem;
  padding: 0.5rem;
  color: $primary-red;
}
.containerImage {
  display: flex;
  align-items: center;
  .image {
    margin: 1em 0 1.5em;
    width: 6.5em;
    height: 5.5em;
  }
}
.logo {
  width: 10.25rem;
  height: 10.25rem;
  border-radius: 0.625rem;
}

.logoContainer {
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  min-width: 7.5rem;
  gap: 0.75rem;
}

.button {
  width: 100%;
  padding: 0.1rem;
  background-color: transparent;
  height: 2.25rem;
}

.addButton {
  width: 100%;
  border-radius: 4px;
  border: 0.1rem solid $primary-dark-violet;
}

.added {
  background: $primary-light-green;
  border-color: $primary-light-green;

  &:hover {
    background: $primary-red;
    border-color: $primary-red;

    span {
      display: none;
    }

    &:after {
      content: '';
    }

    &:after {
      content: 'REMOVE';
    }
  }
}

.icon {
  padding-right: 0.5rem;
  max-width: 2rem;
  max-height: 1rem;
  width: 1.5rem;
}

.noContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.adsTitle {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 0.938rem;
}

.adsConfigurationContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;
  width: 100%;
}

.adPlacements {
  display: grid;
  row-gap: 0.5rem;
}

.adFormats {
  display: grid;
  grid-auto-flow: column;
  row-gap: 0.5rem;
}

.selectedAdType {
  display: flex;
  align-items: center;
}

.selected {
  width: 1.2rem;
  height: 1rem;
  background: linear-gradient(
    to right,
    $primary-light-violet,
    $primary-dark-violet
  );
  border-radius: 5px;
  margin-right: 0.5rem;
}

.bold {
  text-transform: capitalize;
  margin: 0;
  font-weight: bold;
}

.podcastCalendarModal {
  max-width: 90% !important;
  width: 90% !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.podcastCalendarContainer {
  overflow: hidden;
  padding: 0.5rem 0;
}

.deleteCampaignModal {
  max-width: fit-content;
}

@media screen and (min-width: $min-tablet-size) {
  .podcastCalendarModal {
    width: 80% !important;
  }
}

@media screen and (min-width: $min-full-desktop-size) {
  .podcastCalendarModal {
    width: 60% !important;
  }

  .podcastCalendarContainer {
    padding: 2.5rem 0;
  }
}

@media screen and (min-width: $min-ultra-desktop-size) {
  .podcastCalendarModal {
    width: 45% !important;
  }
}
@media screen and (min-width: $min-tablet-size) {
  .offerDetails {
    flex-direction: row;
    margin: 0 2rem 0 1rem;
  }

  .logoContainer {
    display: block;
  }

  .logo {
    width: 6.25rem;
    height: 6.25rem;
  }

  .actions {
    margin: 1rem 0 1rem 2rem;
    width: auto;
  }

  .adsConfigurationContainer {
    display: grid;
    grid-template-columns: 30% 70%;
    margin-bottom: 0.5rem;
    padding: 0;
  }

  .button {
    height: 1.5rem;
  }
}
