@import 'assets/styles/colors';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.isDisabled {
  background-color: $secondary-gray !important;
  border-color: $primary-gray;
  box-shadow: none;
  color: $primary-gray;
}

.labelText {
  margin-left: 0.25rem;
}

.input {
  font-family: 'Monserrat';
  border-radius: 8px;
  color: $primary-black;
  border: 1px solid $primary-gray;
  padding: 0.875rem 1.063rem;
  background: $primary-white;
  box-shadow: 0 12px 23px rgba(55, 125, 255, 0.06);

  &::placeholder {
    color: $primary-gray;
  }

  &:focus {
    border: 1px solid $primary-dark-violet;
    color: $primary-black;
    outline: none;
  }
}

.leftLabelInput {
  width: 100%;
}

.inputHasIconLeft {
  padding: 0.875rem 0 0.875rem 3.125rem;
}

.inputHasIconRight {
  padding: 0.875rem 3.125rem 0.875rem 0.875rem;
}

.icon {
  position: absolute;
  width: 1.25rem;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hasError {
  border: 1px solid $primary-red;
  outline: none;
  box-shadow: 0 12px 23px rgba(255, 86, 48, 0.06);

  &:focus {
    outline: none;
  }
}

.button {
  background-color: $primary-white;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: $primary-white;
  }
}

.disabledButton {
  background-color: $primary-gray;

  &:hover {
    background-color: $primary-gray;
  }
}

.readOnly {
  &:hover {
    cursor: auto;
  }

  &:focus {
    cursor: auto;
    outline: none;
    color: #7b88a8;
  }
}

.iconWithLabelTop {
  transform: translate(-50%);
}

.rightIcon {
  right: 0;
  padding-right: 0.5rem;
}

.leftIcon {
  left: 1.563rem;
}

.imageIcon {
  padding-right: 0.2rem;
}

.iconWithLabelLeft {
  padding-top: 0;
}
