@import 'assets/styles/colors';

.container {
  width: 100%;
  background-color: $primary-white;
  position: fixed;
  align-self: center;
  background: $primary-white;
  display: flex;
  justify-content: center;
  z-index: 100;
  height: 7rem;
  align-items: center;
}

.logo {
  height: 3rem;
}
