@import 'assets/styles/colors';

.main {
  display: flex;
  justify-content: center;
  height: 100%;
}

.left {
  flex: 0.5;
  background: $primary-white;
  background-image: url(../../../assets/patterns/background-image.png);
  background-size: cover;
  background-color: $background-image;
  display: flex;
  justify-content: flex-end;
}

.text {
  color: $primary-white;
  width: 100%;
  text-align: end;
  margin-right: 2.5rem;
  margin-top: 7rem;
}

.right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  flex-direction: column;
}

.children {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
  width: 40%;
  align-self: center;
}

.bold {
  font-weight: bold;
}

@media screen and (max-width: 760px) {
  .left {
    display: none;
  }

  .footer {
    width: 60%;
  }
}

@media screen and (min-width: 760px) and (max-width: 1000px) {
  .left {
    flex: 1;
  }

  .footer {
    width: 60%;
  }
}
