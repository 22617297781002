.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1.25rem;
  button {
    margin-left: 0.625rem;
  }
}

.bold {
  font-weight: bold;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
