@import 'assets/styles/colors';
@import 'assets/styles/breakpoints';

.container {
  display: flex;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
}

.image {
  width: 5rem;
  height: 5rem;
  align-self: center;
}

.info {
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
  width: 100%;
}

.title {
  margin: 0;
  font-weight: bold;
}

.checkboxes {
  display: grid;
  grid-template-columns: auto auto auto;
}

.checkboxLabel {
  font-weight: bold;
}

.checkboxItem {
  margin-right: 2rem;
  margin-bottom: 0;
}

.delete {
  width: 2rem;
  height: 2rem;
  align-self: center;
  margin-right: 1rem;
}

.budgetContainer {
  display: flex;
  flex-wrap: wrap;
}

.budgetCan {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.episode {
  border: 1px solid $primary-dark-violet;
  padding: 0.5rem;
  border-radius: 8px;
}

.impressions {
  border-left: 1px solid $primary-black;
  margin-left: 1rem;
  padding: 0.5rem;
  padding-right: 0;
  display: flex;
  align-self: center;
}

.body {
  margin: 0 0.2rem;
}

.budget {
  border-left: 1px solid $primary-black;
  margin-left: 1rem;
  padding: 0.5rem;
  color: $primary-red;
}

.inputContainer {
  margin: 0.5rem 0 1rem 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 80%;
  max-width: 20rem;
}

.modifierContainer {
  display: flex;
  flex-direction: column;
}

.manipulation {
  padding: 0.8rem;
  margin-left: 0.2rem;
  background-color: transparent;
}

@media screen and (min-width: $min-tablet-size) and (max-width: $min-full-desktop-size) {
  .budget {
    margin-left: 0.7rem;
  }

  .impressions {
    margin-left: 0.7rem;
  }

  .delete {
    margin-right: 0;
  }

  .info {
    padding-left: 1rem;
  }
}

@media screen and (max-width: $min-tablet-size) {
  .container {
    flex-direction: column;
  }

  .image {
    align-self: flex-start;
    margin-left: 1rem;
  }

  .delete {
    position: absolute;
    right: 0;
    margin-right: 15%;
    margin-top: 1rem;
  }

  .info {
    padding-left: 1rem;
    margin-top: 1rem;
  }

  .checkboxes {
    flex-flow: nowrap;
  }

  .checkboxItem {
    margin-right: 1rem;
    margin-bottom: 0;
  }
}
