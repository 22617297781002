@import 'assets/styles/breakpoints';

.container {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 7.5rem;
  min-height: 82%;
  justify-content: space-between;
  min-width: 100%;
  align-items: center;
}

.spinner {
  margin-top: 15rem;
  align-self: center;
}

@media screen and (max-width: $max-medium-size) {
  .footer {
    width: 70%;
  }
}
