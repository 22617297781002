@import 'assets/styles/colors';
@import 'assets/styles/breakpoints';

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  width: 100%;
}

.content {
  width: 100%;
  min-height: 100%;
  padding-top: 1rem;
}

.title {
  text-align: center;
  width: 100%;
}

.spinner {
  margin-top: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formControlInput {
  margin-top: 1rem !important;
  width: 70% !important;
  margin: 0 auto !important;
}

.formControlSlider {
  width: 100% !important;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: $max-small-size) {
  .formControlSlider {
    width: 50% !important;
  }
}

.buttonsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  margin: 2rem 0 0;
}

.goBackButton {
  width: 90%;
  margin: 0 auto;
  background: linear-gradient(
    to right,
    $tertiary-gradient-dark-gray,
    $tertiary-gradient-light-gray
  );
  border: none;
}

.submitButton {
  width: 90%;
  margin: 0 auto;
  background: linear-gradient(
    to right,
    $primary-dark-violet,
    $primary-light-violet
  );
  border: none;
}

.buttonsContainerWidth {
  width: 90%;
}

.form {
  text-align: center;
  white-space: pre-line;
  width: 100%;
  margin: 0 auto;
}

.multipleRectangleButtonsList {
  width: 90%;
  margin: 0 auto;
}

.multipleRectangleButtonsListButton {
  text-transform: uppercase;
}

.formControlLabel {
  font-size: 0.825rem !important;
}

.link {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

@media screen and (min-width: $max-small-size) {
  .formControlLabel {
    font-size: 1.125rem !important;
  }

  .formControlInput {
    width: 55% !important;
  }

  .submitButton,
  .goBackButton {
    width: 30%;
    margin: 0;
  }

  .buttonsContainerWidth {
    width: 60%;
  }

  .buttonsContainer {
    flex-direction: row;
  }

  .buttonsContainer {
    margin-top: 8rem;
  }

  .multipleRectangleButtonsList {
    width: 80%;
  }
}

@media screen and (min-width: $min-medium-size) {
  .formControlInput {
    width: 45% !important;
  }

  .submitButton,
  .goBackButton {
    width: 25%;
    margin: 0;
  }

  .buttonsContainerWidth {
    width: 50%;
  }

  .multipleRectangleButtonsList {
    width: 75%;
  }
}

@media screen and (min-width: $min-tablet-size) {
  .content {
    width: 100%;
  }

  .formControlInput {
    width: 40% !important;
  }

  .submitButton,
  .goBackButton {
    width: 20%;
    margin: 0;
  }

  .buttonsContainerWidth {
    width: 40%;
  }

  .multipleRectangleButtonsList {
    width: 70%;
  }
}

@media screen and (min-width: $min-desktop-size) {
  .formControlInput {
    width: 35% !important;
  }

  .multipleRectangleButtonsList {
    width: 65%;
  }
}

@media screen and (min-width: $min-wide-desktop-size) {
  .formControlInput {
    width: 30% !important;
  }

  .multipleRectangleButtonsList {
    width: 60%;
  }
}

.slider {
  width: 50%;
}
