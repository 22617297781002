@import 'assets/styles/colors';
@import 'assets/styles/breakpoints';

.container {
  border: 1px solid $primary-dark-violet;
  border-radius: 10px;
  margin-top: 1rem;
  width: 100%;
  transition: all 0s ease-in-out 0.6s;
  position: relative;
}

.content {
  position: sticky;
}

.upperContent {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  .adFormatContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    height: 30px;
    border-radius: 0 8px 0 0;
    top: 0;
    width: 28%;
    background: linear-gradient(
      to right,
      $primary-light-violet,
      $primary-dark-violet
    );
    .title {
      font-size: 14px;
      color: white;
      font-weight: normal;
    }
  }
  .containerLeftColumn {
    margin: 1rem 0 0;
    display: flex;
    .containerTitleImage {
      margin-top: 1rem;
      .image {
        margin-left: 1rem;
        width: 6.5em;
        height: 4.5em;
      }
    }
    .title {
      font-weight: bold;
      margin: 1rem 1em 0;
    }
  }
  .campaignDetails {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem 1rem;
  }
}

.noContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.statistics {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
}

.estimateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  width: 30%;
}

.details {
  display: flex;
  flex-direction: column;
  border-left: 1px solid $primary-dark-violet;
  padding-left: 0.5rem;
  margin: 1rem 3rem;
  margin-left: 0;
}

.text {
  margin: 0;
}

.budgetAmount {
  margin-top: 0.5rem;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.seeMore {
  display: flex;
  height: fit-content;
  align-items: center;
  padding: 0;
  margin-top: 0.5rem;
}

.icon {
  width: 1rem;
  margin-right: 0.5rem;
}

.sitBack {
  padding: 1rem 0;
}

.expand {
  display: flex;
  justify-content: center;
}

.expandText {
  color: $primary-dark-violet;
}

.closeIcon {
  transform: rotate(180deg);
  width: 25%;
}

.expanded {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  background-color: $secondary-light-violet;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 400px;
  transition: all 0.6s ease-in-out;
  overflow: auto;
}

.visible {
  opacity: 1;
  transition: all 0.6s ease-in-out;
}

.offerStatus {
  margin-right: 1rem;
  margin-top: 2rem;
  display: flex;
  align-items: center;
}

.status {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 10px;
  margin-right: 0.5rem;
}

.sendButton {
  font-size: 12px;
  padding: 4px;
}

.green {
  background-color: $primary-light-green;
}

.yellow {
  background-color: $primary-yellow;
}

.red {
  background-color: $primary-red;
}

.noMargin {
  margin: 0;
}

.value {
  margin: 0;
}

.label {
  margin: 0;
  text-align: center;
}

.finishedCampaign {
  justify-content: center;
}

@media screen and (max-width: $max-medium-size) {
  .campaignDetails {
    flex-direction: column;
  }

  .noMargin {
    margin: 0;
  }
}

.containerButtons {
  display: flex;
  flex-direction: column;
  width: 24%;
  .talkingPoints {
    margin: 10px 0;
    font-size: 12px;
    padding: 4px 1rem;
    width: 100%;
    height: 45px;
  }
}

.talkingPointsAllModal {
  width: 40%;
  height: auto;
  padding-top: 1rem;
  overflow-y: auto;
}
