.container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  align-items: center;
  width: 100%;
}

.spinner {
  margin-top: 15rem;
  align-self: center;
}
