@import 'assets/styles/colors';

.container {
  display: flex;
}

.tooltip {
  visibility: hidden;
  background-color: $primary-white;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border: 1px solid $primary-black;
  padding: 0.5rem;
  z-index: 100;
  position: absolute;
  margin-left: 1rem;
  box-shadow: 0 0 10px $primary-gray;
}

.component:hover + .tooltip {
  visibility: visible;
}
