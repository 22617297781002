@import 'assets/styles/colors';

.container {
  margin-bottom: 1rem;
  display: flex;
}

.label {
  margin-left: 0.5rem;
}

.button {
  background: none;
  border: none;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
}

.checkbox {
  border: 1px solid $primary-dark-violet;
  border-radius: 3.2px;
  width: 1.2rem;
  height: 1rem;
  padding: 0.1rem;
}

.selected {
  background: linear-gradient(
    to right,
    $primary-light-violet,
    $primary-dark-violet
  );
  border-radius: 3.2px;
  width: 100%;
  height: 100%;
}
