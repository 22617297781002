@import 'assets/styles/breakpoints';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
}
.subtitle {
  font-size: 13px;
}

.logo {
  width: 5rem;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.button {
  width: 50%;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: $min-medium-size) {
  .button {
    width: 100%;
  }
}
