@import 'assets/styles/colors';

.badge {
  font-family: 'Monserrat';
  border: none;
  display: flex;
  border-radius: 4.8px;
  align-self: center;
  padding: 0 0.8rem;
  margin: 0.2rem;
  cursor: pointer;
}

.text {
  margin: 0;
  padding: 0 0.2rem;
  width: max-content;
}

.icon {
  display: flex;
  align-items: center;
}

.primary {
  background: linear-gradient(
    to right,
    $primary-light-violet,
    $primary-dark-violet
  );
  border: 1px solid;
  color: $primary-white;
}

.secondary {
  background: $primary-white;
  color: $primary-dark-violet;
  border: 1px solid $primary-dark-violet;
}

.s {
  font-size: 0.75rem;
  font-weight: 500;
}

.m {
  font-size: 1rem;
  font-weight: 500;
}

.spinner {
  padding: 5rem 5rem;
}
