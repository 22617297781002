@import 'assets/styles/breakpoints';

.content {
  width: 100%;
  margin: 0 auto;
}

.buttonsContainer {
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}

.estimatedImpressions {
  margin: 2rem auto 0;
  width: 100%;
  flex-direction: row !important;
  justify-content: center;
}

.estimatedImpressionsTitle {
  font-size: 2rem !important;
}

.fields {
  margin: 2rem auto 0;
}

@media screen and (min-width: $max-small-size) {
  .content {
    width: 70%;
  }

  .estimatedImpressionsTitle {
    font-size: 4rem !important;
  }

}

@media screen and (min-width: $min-medium-size) {
  .content {
    width: 60%;
  }

}

@media screen and (min-width: $min-tablet-size) {
  .content {
    width: 55%;
  }

}

@media screen and (min-width: $min-desktop-size) {
  .content {
    width: 45%;
  }

  .estimatedImpressions {
    flex-direction: column !important;
    margin: 3rem 0 0;
    width: 30%;
    position: fixed;
    left: 0;
  }

  .fields {
    margin-bottom: 5rem;
    width: 60%;
  }
}

@media screen and (min-width: $min-wide-desktop-size) {
  .content {
    width: 40%;
  }
}

@media screen and (min-width: $min-full-desktop-size) {
  .fields {
    width: 50%;
  }
}
