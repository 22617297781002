@import 'assets/styles/colors';

.container {
  display: flex;
  margin: 10px auto auto;
  align-items: center;
  width: 100%;
  .sort {
    border-radius: 5px;
    color: $primary-black;
    border: 1px solid $primary-gray;
    margin-left: 1rem;
    width: 30%;
  }
  .searchInput {
    border-radius: 5px;
    height: 50px;
  }
}