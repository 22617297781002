@import 'assets/styles/colors';

.body {
  margin-top: 1rem;
}

.adType {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  flex-direction: column;
  align-items: center;
}

.adTypeButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $primary-light-violet;
  border-radius: 16px;
  margin: 0.5rem 0 0 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  padding: 1rem;
}

.adTypeTitle {
  margin: 0;
  text-transform: inherit;
  font-weight: bold;
}

.selectedAdType {
  border: 1px solid $primary-dark-violet;
  background-color: $secondary-light-violet;
}